import axios from 'axios';
import { useState } from "react";
import Loader from './Loader';

export default function App() {
  
  const initialState = { username: '', password: '' };
  const [formState, setFormState] = useState(initialState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const returnSite = new URLSearchParams(window.location.search).get('return');

  const handleUsernameChange = (event) => {
    setFormState({ ...formState, username: event.target.value });
  }

  const handlePasswordChange = (event) => {
    setFormState({ ...formState, password: event.target.value });
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    if (validateForm(formState)) {
      axios.post(`${process.env.REACT_APP_API_URL}/login`, formState, {withCredentials: true})
        .then(res => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(res.data);
          }
          
          if (res.data.error) {
            setError(res.data.message);
          } else {
            if (returnSite) {
              window.location.href = returnSite;
            } else {
              window.location.href = process.env.REACT_APP_REDIRECT_URL;
            }
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          setFormState(initialState);
          setLoading(false);
        });
    } else {
      setError('Username/Email and Password are required');
    }
  }

  const validateForm = (form) => {
    return form.username && form.password;
  }

  return (
    <div className="page flex flex-col justify-center items-center h-full">
      <div className="form-wrapper bg-white shadow mx-6 my-6 px-6 py-6 rounded">
        <img src="bsf_logo.png" alt="Basicsafe Logo" />
        <h1 className="text-2xl text-center font-medium mb-6 text-gray-600">Admin Login</h1>

        {error && <div className="border-2 border-red-400 px-4 py-2 text-sm mb-4 text-red-400 rounded">{error}</div>}

        <form onSubmit={(event) => handleFormSubmit(event)}>
          <div className="form-item mb-6">
            <label htmlFor="username">Username / Email</label>
            <input id="username" name="username" type="text" onChange={(event) => handleUsernameChange(event)} value={formState.username} required />
          </div>

          <div className="form-item mb-8">
            <label htmlFor="password">Password</label>
            <input id="password" name="password" type="password" onChange={(event) => handlePasswordChange(event)} value={formState.password} required />
          </div>

          <div className="text-right mb-6 flex justify-end items-center">
            <a href="https://accounts.basicsafe.blr.com/account/forgot" target="_blank" rel="noreferrer" className="text-sm text-gray-500 mr-6">Forgot Password?</a>
            {!loading && <button type="submit" className="button1">Log in</button>}
            {loading && <Loader />}
          </div>

          <p className="text-center text-sm text-gray-400">Need help? Contact customer service at service@blr.com or call 800-727-5257</p>
        </form>
      </div>

      <a href="https://basicsafe.blr.com/login.php" className="block text-center text-gray-400 my-4">Switch to Employee Login</a>
    </div>
  );
}